/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Link from '../link'
import Icon from '../icon'

const Button = (props) => {
  const {
    to,
    type,
    label,
    color: _color,
    display,
    invert,
    icon,
    ...otherProps
  } = props

  let color =
    display === 'secondary'
      ? 'grey2'
      : _color
      ? _color
      : true
      ? 'x'
      : true
      ? 'x'
      : 'white'
  let textColor = display === 'secondary' ? 'white' : 'black'
  if (invert) {
    const tempColor = color
    color = textColor
    textColor = tempColor
  }

  return (
    <Link
      to={to}
      type={type}
      sx={{
        position: 'relative',
        variant: 'text.mono',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: color,
        backgroundColor: color,
        px: display === 'secondary' ? 5 : [5, 7],
        py: display === 'secondary' ? '8px' : ['8px', '18px'],
        display: 'inline-block',
        borderRadius: '27px',
        overflow: 'hidden',

        '&:before': {
          content: '" "',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: '0',
          top: '0',
          backgroundColor: display === 'secondary' ? 'grey4' : 'black',
          transition: 'transform 0.3s ease-in-out',
          transform: 'translate3d(-110%,0,0)',
          borderRadius: '27px',
        },
      }}
      {...otherProps}
    >
      {icon ? (
        <Icon
          icon={icon}
          sx={{
            position: 'absolute',
            top: '50%',
            width: '24px',
            left: '8px',
            mt: '-12px',
          }}
        />
      ) : null}
      <span
        sx={{
          position: 'relative',
          zIndex: 2,
          color: textColor,
          transition: 'color 0.3s ease-in-out',
          pl: icon ? '24px' : null,
        }}
      >
        {label ? label : 'Read more'}
      </span>
    </Link>
  )
}

export default Button
