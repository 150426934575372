/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'
import useInView from 'react-cool-inview'
import { AnimatePresence, motion } from 'framer-motion'
import useSSR from 'use-ssr'

const imgState = {
  NONE: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
}

const loadImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image()

    img.onload = () => {
      resolve(imgState.LOADED)
    }
    img.onerror = () => {
      resolve(imgState.ERROR)
    }

    img.src = src
  })
}

const Img = (props) => {
  const { src, width, height } = props
  const [imgLoaded, setImgLoaded] = useState(imgState.NONE)
  const { inView, observe } = useInView({
    rootMargin: '25px',
  })
  const { isBrowser } = useSSR()

  useEffect(() => {
    const loadImg = async (src) => {
      const res = await loadImage(src)
      setImgLoaded(res)
    }

    if (inView && imgLoaded === imgState.NONE) {
      setImgLoaded(imgState.LOADING)
      loadImg(src)
    }
  }, [inView, imgLoaded])

  return (
    <div
      sx={{
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.1)',
      }}
      ref={observe}
    >
      {src && (imgLoaded === imgState.LOADED || !isBrowser) ? (
        <motion.img
          src={src}
          width={width}
          height={height}
          sx={{
            display: 'block',
            width: '100%',
            height: '100%',
            opacity: 0,
          }}
          transition={{
            type: 'tween',
            duration: 0.2,
          }}
          animate={{
            opacity: 1,
          }}
        />
      ) : null}
    </div>
  )
}

export default Img
