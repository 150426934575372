/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, Embed } from 'theme-ui'
import { css } from '@emotion/react'
import { get, isFinite } from 'lodash'
import useFetch from 'use-http'

import Button from '../button'
import Section from '../section'

import { renderHTML } from '../../lib/helpers'

import { fillArea } from '../../styles/css'
import { Cont, FlexCols, FlexCol } from '../../styles/els'
import ImageFill, { RatioBox, FillContainer } from '../image'

const IFRAMELY_API_KEY = '3b4b96485deec99dc5afa1'

const EmbedThumbnail = (props) => {
  const { data, ...otherProps } = props
  const src = get(data, 'links.thumbnail.0.href')
  const image = {
    src,
    width: get(data, 'links.thumbnail.0.media.width'),
    height: get(data, 'links.thumbnail.0.media.height'),
  }

  return src ? <ImageFill image={image} {...otherProps} /> : null
}

const EmbedContent = (props) => {
  const {
    data,
    color,
    ratio,
    hasClickedPlayState: [hasClickedPlay, setHasClickedPlay],
  } = props

  const src = get(data, 'links.player.0.href')
  const html = get(data, 'html')

  return (
    <div
      css={css`
        ${fillArea}
      `}
      sx={{
        backgroundColor: 'grey4',
      }}
    >
      {hasClickedPlay && html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <React.Fragment>
          <div
            sx={{
              zIndex: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '&:hover': {
                cursor: 'pointer',
              },
            }}
            css={css`
              ${fillArea}
            `}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              setHasClickedPlay(true)
            }}
          >
            <div
              sx={{
                backgroundColor: 'grey1',
                opacity: 0.2,
                zIndex: 1,
              }}
              css={css`
                ${fillArea}
              `}
            />
            <Button
              color={color ? color : 'white'}
              label="Play"
              sx={{
                position: 'relative',
                zIndex: 2,
              }}
            />
          </div>
          <EmbedThumbnail data={data} sx={{ zIndex: 1 }} />
        </React.Fragment>
      )}
    </div>
  )
}

const EmbedWrapper = (props) => {
  const { src, color, contain, ...otherProps } = props
  const hasClickedPlayState = useState(false)
  const url = `https://iframe.ly/api/iframely?url=${src}&api_key=${IFRAMELY_API_KEY}&autoplay=1`
  const { loading, error, data = [] } = useFetch(url, {}, [])
  const isLoading = loading || error

  const _embedRatio = get(data, 'links.player.0.media.aspect-ratio')
  const embedRatio =
    isLoading || !isFinite(_embedRatio) ? 9 / 16 : 1 / _embedRatio

  const embedContent = !isLoading ? (
    <EmbedContent
      data={data}
      hasClickedPlayState={hasClickedPlayState}
      color={color}
      ratio={embedRatio}
    />
  ) : null

  return contain ? (
    <FillContainer
      size={{ width: 100, height: 100 * embedRatio }}
      contain={true}
    >
      {embedContent}
    </FillContainer>
  ) : (
    <RatioBox
      ratio={embedRatio}
      sx={{
        backgroundColor: 'grey3',
      }}
    >
      {embedContent}
    </RatioBox>
  )
}

export default EmbedWrapper
