import colors, { colorData } from './colors'
import { typeData, typeVariants } from './type'
import { breakpointData } from './breakpoints'

import { mathOnCss, mixColor, themeTextBlockPPadding, pxToVW } from '../utils'

export default {
  // BREAKPOINTS
  breakpoints: [...breakpointData],

  // COLORS
  colors: {
    text: colorData.white,
    background: colorData.black,
    ...colorData,
  },

  // SPACES
  space: [0, 4, 8, 12, 16, 20, 32, 40, 80, 120],

  // Z
  zIndices: [0, 1, 50, 1000],

  // RADIUS
  radii: [0, 8],

  // TYPOGRAPHY
  ...typeData,

  // VARIANTS
  text: {
    ...typeVariants,
  },

  textBlock: {
    body: {
      variant: 'text.regular_20',

      'h1, h2, h3': {
        variant: 'text.heading_36',
        pb: 5,
      },

      'p, ul, ol': {
        ...themeTextBlockPPadding(typeData.lineHeights[20], (v) => v * 0.5),
      },

      ul: {
        li: {
          position: 'relative',
          pl: '24px',

          '&:before': {
            content: '"—"',
            position: 'absolute',
            left: 0,
            top: 0,
          },
        },
      },

      a: {
        variant: 'text.bold_20',
        borderBottom: '1px solid white',

        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    25: {
      variant: 'text.regular_25',

      p: {
        ...themeTextBlockPPadding(typeData.lineHeights[25], (v) => v * 0.5),
      },

      a: {
        variant: 'text.bold_25',
      },
    },
  },

  // SET VALUES
  setValues: {
    gridSpace: {
      property: 'space',
      value: [5, null, null, null, 6, 7],
    },
    cardPadding: {
      property: 'space',
      value: [5, 6, 7],
    },
    headerPT: {
      property: 'space',
      value: [3, null, 6],
    },
    headingPadding: {
      property: 'space',
      value: [7, null, null, 8, 9],
    },
    sectionPadding: {
      property: 'space',
      value: [7, null, null, 8],
    },
  },

  // STYLES
  styles: {
    root: {
      ...typeVariants.regular_20,
    },
  },

  useRootStyles: true,
  useColorSchemeMediaQuery: false,
  useCustomProperties: false,
}
