import { getImage } from 'gatsby-plugin-image'
import { get, isString } from 'lodash'
import htmr from 'htmr'

export const formatImage = (image) => {
  if (!image) {
    return null
  }

  const _image =
    get(image, 'localFile.childImageSharp.gatsbyImageData') ||
    get(image, 'childImageSharp.gatsbyImageData') ||
    get(image, 'gatsbyImageData') ||
    image

  const gatsbyImage = getImage(_image)

  if (gatsbyImage) {
    return gatsbyImage
  }

  const publicURL = get(image, 'localFile.publicURL') || get(image, 'publicURL')
  const size = get(image, 'mediaDetails')

  if (publicURL && size) {
    return {
      src: publicURL,
      width: size.width,
      height: size.height,
    }
  }

  return null
}

export const formatVimeo = (vimeo) => {
  if (!vimeo) {
    return null
  }

  return {
    placeholder: formatImage(get(vimeo, 'placeholder')),
    vimeo: get(vimeo, 'vimeoId'),
    mux: get(vimeo, 'mux'),
  }
}

export const renderHTML = (str) => {
  return htmr(str)
}

export const formatDate = (start, end) => {
  return `${isString(start) ? start : ''}${
    isString(start) && isString(end) ? ' — ' : ''
  }${isString(end) ? end : ''}`
}

export const LinkTypes = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  EMAIL: 'email',
  DOWNLOAD: 'download',
}

export const resolveButtons = (buttons) => {
  return buttons && buttons.length > 0
    ? buttons.map(({ type, label, ...linkProps }, _i) => {
        const _link = resolveLink(linkProps)
        return {
          display: type ? type : 'primary',
          label,
          ..._link,
        }
      })
    : []
}

export const resolveLink = (props) => {
  const { linksTo, email, externalLink, internalLink } = props

  let to = ''
  if (linksTo === LinkTypes.INTERNAL) {
    to = get(internalLink, '0.uri')
  } else if (linksTo === LinkTypes.EXTERNAL) {
    to = externalLink
  } else if (linksTo === LinkTypes.EMAIL && email) {
    to = `mailto:${email}`
  }

  return {
    type: linksTo,
    to,
  }
}

export const ratioForImage = (ratioType) => {
  switch (ratioType) {
    case 'square':
      return 1
    case 'portrait':
      return 5 / 4
    case 'landscape':
      return 4 / 5
  }
}
