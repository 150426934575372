import { buildColorObject } from '../utils'

const colors = {
  black: [0, 0, 0],
  white: [255, 255, 255],
  grey1: [125, 125, 125],
  grey2: [69, 69, 69],
  grey3: [37, 37, 37],
  grey4: [21, 21, 21],
  green: [49, 239, 121],
  red: [253, 56, 56],
  orange: [252, 176, 56],
  purple: [153, 77, 237],
}

export default colors

export const colorData = buildColorObject(colors)
