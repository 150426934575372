/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { isFinite, get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { motion, useMotionValue } from 'framer-motion'
import { mapRange } from 'canvas-sketch-util/math'
import { rgba } from 'polished'

import Link from '../link'
import Logo from '../logo'

import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'
import {
  useThemeUISetValue,
  useValueForScreenWidth,
  valueForScreenWidth,
  valueFromTheme,
} from '../../styles/utils'
import { fillArea } from '../../styles/css'
import useScreenSize from '../../lib/hooks/use-screen-size'

const query = graphql`
  query {
    topPages: allWpPage(
      sort: { order: ASC, fields: menuOrder }
      filter: {
        acfPage: { navigationLevel: { in: ["primary", "secondary"] } }
        parentId: { eq: null }
      }
    ) {
      nodes {
        title
        uri
        parentId
      }
    }
    menuDropdownPrimaryPages: allWpPage(
      sort: { order: ASC, fields: menuOrder }
      filter: {
        acfPage: { navigationLevel: { in: ["primary"] } }
        parentId: { eq: null }
      }
    ) {
      nodes {
        title
        uri
        parentId
        wpChildren {
          nodes {
            ... on WpPage {
              title
              uri
            }
          }
        }
      }
    }
    menuDropdownSecondaryPages: allWpPage(
      sort: { order: ASC, fields: menuOrder }
      filter: {
        acfPage: { navigationLevel: { in: ["secondary", "dropdown"] } }
        parentId: { eq: null }
      }
    ) {
      nodes {
        title
        uri
        parentId
      }
    }
  }
`

const inTransition = {
  duration: 0.6,
  type: 'tween',
}

const outTransition = {
  duration: 0.2,
  type: 'tween',
}

const logoVariants = {
  in: {
    opacity: 1,
    transition: inTransition,
  },
  out: {
    opacity: 0,
    transition: outTransition,
  },
}

const LinkList = (props) => {
  const { links, children, ...otherProps } = props

  return (
    <ul
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }}
      {...otherProps}
    >
      {links && links.length > 0
        ? links.map((_link, _i) => {
            return _link.parentId ? null : (
              <li
                key={_i}
                sx={{
                  '&:first-child': {
                    ml: -4,
                  },
                  '&:last-child': {
                    mr: -4,
                  },
                }}
              >
                <Link
                  to={_link.uri}
                  sx={{
                    px: 4,
                    pt: '14px',
                    pb: '18px',
                    display: 'block',
                    variant: 'text.regular_16',
                  }}
                >
                  {_link.title}
                </Link>
              </li>
            )
          })
        : null}
      <li>{children}</li>
    </ul>
  )
}

const MenuLinks = (props) => {
  const { links } = props

  return links && links.length > 0 ? (
    <FlexCols space={0}>
      {links.map((_link, _i) => {
        const _otherLinks = get(_link, 'wpChildren.nodes') || []
        const _links = [_link, ..._otherLinks]
        return (
          <FlexCol key={_i} base={[1, null, null, 2]}>
            {_links && _links.length > 0
              ? _links.map((__link, _j) => {
                  const __first = _j === 0
                  return (
                    <Link
                      key={`${_i}-${_j}`}
                      to={__link.uri}
                      sx={{
                        variant: 'text.regular_36',
                        display: 'block',
                        color: __first ? 'white' : 'grey1',
                        pr: [0, null, null, 3],
                      }}
                    >
                      {__link.title}
                    </Link>
                  )
                })
              : null}
          </FlexCol>
        )
      })}
    </FlexCols>
  ) : null
}

const Header = (props) => {
  const { path } = props

  const [menuOpen, setMenuOpen] = useState(false)

  const { width: screenWidth } = useScreenSize()
  const { theme } = useThemeUI()

  const pageData = useStaticQuery(query)
  const topNavPages = get(pageData, 'topPages.nodes')
  const menuPrimaryPages = get(pageData, 'menuDropdownPrimaryPages.nodes')
  const menuSecondaryPages = get(pageData, 'menuDropdownSecondaryPages.nodes')

  const pT = useThemeUISetValue('headerPT', theme)

  const [_mTFrom, _mTTo, navBg, navBgTransparent] = useMemo(() => {
    const navBgHex = valueFromTheme('colors', 'grey3', theme)
    return [
      valueFromTheme('space', pT, theme),
      valueFromTheme('space', [2, null, 3], theme),
      navBgHex,
      rgba(navBgHex, 0),
    ]
  }, [theme])
  const yOffset = useMotionValue(0)
  const [hasScrolled, setHasScrolled] = useState(false)
  const mTFrom = useValueForScreenWidth(_mTFrom, theme, screenWidth)
  const mTTo = useValueForScreenWidth(_mTTo, theme, screenWidth)

  const showHeader = useValueForScreenWidth(
    [false, null, null, null, true],
    theme,
    screenWidth
  )

  const onScroll = () => {
    const yPos = window.scrollY

    const y = mapRange(yPos, 0, mTFrom - mTTo, 0, mTTo - mTFrom, true)
    if (y !== yOffset.get()) {
      yOffset.set(y)
    }

    const _hasScrolled = yPos >= 164
    if (_hasScrolled !== hasScrolled) {
      setHasScrolled(_hasScrolled)
    }
  }

  useEffect(() => {
    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [hasScrolled, mTFrom, mTTo])

  useEffect(() => {
    setMenuOpen(false)
  }, [path])

  const px = [3, 5, 6]
  const mOffset = [-3, -5, -6]

  return (
    <>
      {menuOpen ? (
        <div
          sx={{
            position: 'fixed',
            zIndex: 999,
            width: '100%',
            height: '100%',
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setMenuOpen(false)
          }}
        />
      ) : null}
      <motion.header
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          zIndex: 3,
          pt: pT,
        }}
        style={{
          y: yOffset,
          pointerEvents: hasScrolled ? 'all' : 'none',
        }}
      >
        <Cont>
          <div
            sx={{
              ml: mOffset,
              mr: mOffset,
            }}
          >
            <motion.nav
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
              }}
              variants={{
                in: {
                  backgroundColor: navBg,
                  transition: inTransition,
                },
                out: {
                  backgroundColor: navBgTransparent,
                  transition: { delay: 0.1, ...outTransition },
                },
              }}
              animate={hasScrolled || menuOpen ? 'in' : 'out'}
              initial={false}
              layout
            >
              <motion.div
                layout
                sx={{
                  display: 'flex',
                  justifyContent: 'stretch',
                  alignItems: 'stretch',
                }}
              >
                <motion.div
                  sx={{
                    px: px,
                    pt: '14px',
                  }}
                  variants={logoVariants}
                  animate={hasScrolled && !menuOpen ? 'in' : 'out'}
                  initial={false}
                >
                  <Link to="/">
                    <Logo
                      small={true}
                      sx={{
                        width: ['100px', '120px', null, '140px'],
                      }}
                    />
                  </Link>
                </motion.div>
                <div sx={{ flex: 1 }} />
                {showHeader ? (
                  <motion.div
                    sx={{
                      pointerEvents: 'all',
                    }}
                    variants={logoVariants}
                    animate={!menuOpen ? 'in' : 'out'}
                    initial={false}
                  >
                    <LinkList links={topNavPages} />
                  </motion.div>
                ) : null}
                <div
                  sx={{
                    px: px,
                    ml: 4,
                    borderLeftWidth: '1px',
                    borderLeftStyle: 'solid',
                    borderLeftColor:
                      !hasScrolled || menuOpen ? 'transparent' : 'grey2',
                    display: 'flex',
                    alignItems: 'center',
                    variant: 'text.regular_16',
                    minHeight: ['46px', '49px', null, '52px'],
                    pointerEvents: 'all',

                    span: {
                      display: 'block',
                      pb: '4px',
                    },

                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setMenuOpen(!menuOpen)
                  }}
                >
                  <div
                    sx={{
                      display: 'block',
                      width: '16px',
                      mr: 3,
                      mb: '2px',

                      '& > div': {
                        width: '100%',
                        height: '1px',
                        backgroundColor: 'text',
                        mb: '4px',

                        '&:last-child': {
                          mb: 0,
                        },
                      },
                    }}
                  >
                    <div />
                    <div />
                    <div />
                  </div>
                  {!menuOpen ? <span>Menu</span> : <span>Close</span>}
                </div>
              </motion.div>
              {menuOpen ? (
                <motion.div
                  layout
                  sx={{
                    px: px,
                    pb: 4,
                    pointerEvents: 'all',
                  }}
                >
                  <FlexCols
                    space={0}
                    sx={{
                      pb: 6,
                    }}
                  >
                    {[menuPrimaryPages, menuSecondaryPages].map(
                      (_links, _i) => {
                        return (
                          <FlexCol base={[1, null, null, null, 2]} key={_i}>
                            <MenuLinks links={_links} />
                          </FlexCol>
                        )
                      }
                    )}
                  </FlexCols>
                </motion.div>
              ) : null}
            </motion.nav>
          </div>
        </Cont>
      </motion.header>
    </>
  )
}

export default Header
