/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact } from 'lodash'

import { RatioBox } from '../image'

import { floodBgImage } from '../../styles/css'

import Instagram from './instagram.svg'
import Twitter from './twitter.svg'
import Youtube from './youtube.svg'

const iconMap = {
  instagram: Instagram,
  twitter: Twitter,
  youtube: Youtube,
}

const Icon = (props) => {
  const { icon: _icon, ...otherProps } = props

  const icon = iconMap[_icon]

  return icon ? (
    <RatioBox
      css={css`
        ${floodBgImage};
        background-image: url(${icon});
      `}
      {...otherProps}
    />
  ) : null
}

export default Icon
