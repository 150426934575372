/** @jsx jsx */
import React, { useMemo, useRef, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get, find } from 'lodash'
import { atom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { motion, AnimatePresence } from 'framer-motion'

import { hardwareAccelerate } from '../../styles/css'

import { settings as animationSettings } from '../../lib/animation'

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.9,
      ease: animationSettings.easings.easeInOut,
    },
  },
  out: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: animationSettings.easings.easeInOut,
    },
  },
}

const PageTransition = (props) => {
  const { children, path } = props

  return (
    <AnimatePresence exitBeforeEnter={true}>
      <motion.div
        key={path}
        sx={{ position: 'relative', zIndex: 1 }}
        variants={pageVariants}
        initial="initial"
        animate="in"
        exit="out"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default PageTransition
