/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import WebFont from 'webfontloader'
import { atom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'

import Header from '../components/header'
import MemberStoryOverlay from '../components/member-story-overlay'
import PageTransition from '../components/page-transition'

import Global from '../styles/global'

const Layout = (props) => {
  const { children, path, ...otherProps } = props

  useEffect(() => {
    if (WebFont) {
      WebFont.load({
        typekit: {
          id: 'qbf7oug',
        },
        google: {
          families: ['Azeret Mono:400'],
        },
      })
    }
  })
  return (
    <div sx={{ variant: 'text.regular_20' }}>
      <Global />
      <Header path={path} />
      <PageTransition path={path} {...otherProps}>
        {children}
      </PageTransition>
      <MemberStoryOverlay />
    </div>
  )
}

export default Layout
