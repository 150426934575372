/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import { RatioBox } from '../image'

import { fillArea, floodBgImage } from '../../styles/css'

import LogoImg from './logo.png'
import LogoShortImg from './logo_short.png'

export const logoRatio = 1100 / 2000
export const logoShortRatio = 170 / 816

const Logo = (props) => {
  const { small, ...otherProps } = props

  const ratio = small ? logoShortRatio : logoRatio
  const img = small ? LogoShortImg : LogoImg

  return (
    <RatioBox ratio={ratio} {...otherProps}>
      <div
        sx={{
          backgroundImage: `url(${img})`,
        }}
        css={css`
          ${fillArea};
          ${floodBgImage};
        `}
      />
    </RatioBox>
  )
}

export default Logo
