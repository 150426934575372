/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { isArray } from 'lodash'

import Section from '../section'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol } from '../../styles/els'

import Embed from './embed'

const VideoWrapper = (props) => {
  const { label, videoType, embed, mux, contain, ...otherProps } = props

  return (
    <React.Fragment>
      {videoType === 'embed' ? <Embed src={embed} contain={contain} /> : null}
      {label && !contain ? (
        <div sx={{ variant: 'text.bold_20', pt: 5 }}>{label}</div>
      ) : null}
    </React.Fragment>
  )
}

export default VideoWrapper
