/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'

import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'
import { useThemeUISetValue } from '../../styles/utils'

const Section = (props) => {
  const { children, inset, full, bg, bgEl, ...otherProps } = props

  const { theme } = useThemeUI()
  const padding = useThemeUISetValue('sectionPadding', theme)

  return (
    <section
      sx={{
        position: 'relative',
        backgroundColor: bg ? bg : null,
        pt: full ? null : inset ? padding : null,
        pb: full ? null : padding,
        mb: inset || full ? padding : null,

        '&:last-child': {
          mb: 0,
        },
      }}
      {...otherProps}
    >
      {full ? (
        children
      ) : (
        <Cont
          sx={{
            position: 'relative',
            zIndex: 2,
          }}
        >
          {children}
        </Cont>
      )}
      {bgEl ? (
        <div
          sx={{
            zIndex: 1,
          }}
        >
          {bgEl}
        </div>
      ) : null}
    </section>
  )
}

export default Section
