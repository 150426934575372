import React, { useState, useEffect } from 'react'
import iOSInnerHeight from 'ios-inner-height'
import useSSR from 'use-ssr'

export default () => {
  const { isBrowser } = useSSR()

  const getScreenSize = (init = false) => {
    return {
      width: init
        ? null
        : isBrowser
        ? window.innerWidth || document.documentElement.clientWidth
        : 1280,
      height: init
        ? null
        : isBrowser
        ? window.innerHeight || document.documentElement.clientHeight
        : 720,
      fullHeight: init ? null : isBrowser ? iOSInnerHeight() : 720,
    }
  }

  const [screenSize, setScreenSize] = useState(getScreenSize(true))

  useEffect(() => {
    const resizeHandler = () => {
      setScreenSize(getScreenSize())
    }
    setScreenSize(getScreenSize())
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [isBrowser])

  return screenSize
}
