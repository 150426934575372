/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { atom, useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { graphql, useStaticQuery } from 'gatsby'
import { get, find } from 'lodash'

import VideoWrapper from '../video-wrapper'

import { fillArea } from '../../styles/css'

export const activeMemberStoryAtom = atom(null)

const query = graphql`
  query {
    stories: allWpStory {
      nodes {
        title
        uri
        id
        acfStory {
          thumbnail {
            ...InlineImage
          }
          video {
            videoType
            embed
            mux
          }
        }
      }
    }
  }
`

const MemberStory = (props) => {
  const { id } = props

  const data = useStaticQuery(query)
  const story = useMemo(() => {
    return find(get(data, 'stories.nodes'), { id: id })
  }, [id, data])

  const setActiveMemberStory = useUpdateAtom(activeMemberStoryAtom)

  const video = get(story, 'acfStory.video')

  return story ? (
    <div
      sx={{
        position: 'fixed',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 1001,
      }}
    >
      <div
        css={css`
          ${fillArea}
        `}
        sx={{
          zIndex: 1,
          backgroundColor: 'black',
          opacity: 0.9,
        }}
      />
      <div
        css={css`
          ${fillArea}
        `}
        sx={{
          zIndex: 2,
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          py: 7,
          px: 8,
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          setActiveMemberStory(null)
        }}
      >
        <div
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 2,
            width: '60px',
            height: '60px',

            '&:hover': {
              cursor: 'pointer',
            },

            '& > div': {
              width: '30px',
              height: '2px',
              backgroundColor: 'white',
              position: 'absolute',
              left: '50%',
              top: '50%',
              marginLeft: '-15px',
              marginTop: '-1px',
            },
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setActiveMemberStory(null)
          }}
        >
          <div
            sx={{
              transform: 'rotate(-45deg)',
            }}
          />
          <div
            sx={{
              transform: 'rotate(45deg)',
            }}
          />
        </div>
        <div
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          {video ? <VideoWrapper {...video} contain={true} /> : null}
        </div>
      </div>
    </div>
  ) : null
}

const MemberStoryOverlay = (props) => {
  const [activeMemberStory] = useAtom(activeMemberStoryAtom)

  return activeMemberStory ? (
    <MemberStory key={activeMemberStory} id={activeMemberStory} />
  ) : null
}

export default MemberStoryOverlay
