import { isFinite } from 'lodash'

export const roundTo = (num, round, type = null) => {
  switch (type) {
    case 'floor':
      return Math.floor(num * round) / round
    case 'ceil':
      return Math.ceil(num * round) / round
    default:
      return Math.round(num * round) / round
  }
}

export const fitDimensionsToBox = (box, dimensions, contain = false) => {
  let width = box.width
  let height = isFinite(dimensions.ratio)
    ? box.width * dimensions.ratio
    : (dimensions.height / dimensions.width) * box.width

  if (
    isFinite(box.height) &&
    ((!contain && height < box.height) || (contain && height > box.height))
  ) {
    height = box.height
    width = isFinite(dimensions.ratio)
      ? box.height / dimensions.ratio
      : (dimensions.width / dimensions.height) * box.height
  }

  return {
    width: roundTo(width, 100),
    height: roundTo(height, 100),
  }
}

const calcRatioForSize = (size = {}) => {
  if (!size) {
    return 1
  }
  const { width, height } = size
  return isFinite(width) && isFinite(height) ? height / width : 1
}

export const calcRatio = (size = {}, ratio = null, maxRatio = null) => {
  if (isFinite(ratio)) {
    return ratio
  }

  const sizeRatio = calcRatioForSize(size)
  return isFinite(maxRatio) ? Math.min(maxRatio, sizeRatio) : sizeRatio
}
