import { xor } from 'lodash'
import { buildTypeObject, buildTypeVariants, pxToVW } from '../utils'

const monoFallback = `monospace`
const sansFallback = `'Helvetica Neue', Helvetica, Arial, sans-serif`

const styles = {
  light: {
    font: `degular, 'Degular', ${sansFallback}`,
    weight: 300,
  },
  regular: {
    font: `degular, 'Degular', ${sansFallback}`,
    weight: 400,
  },
  bold: {
    font: `degular, 'Degular', ${sansFallback}`,
    weight: 700,
  },
  regular_display: {
    font: `degular-display, 'Degular Display', ${sansFallback}`,
    weight: 400,
  },
  bold_display: {
    font: `degular-display, 'Degular Display', ${sansFallback}`,
    weight: 700,
  },
  mono: {
    font: `'Azeret Mono', ${monoFallback}`,
    weight: 400,
  },
}

const sizes = {
  12: ['12px', '15px', '0px'],
  mono_12: ['12px', '15px', '0px'],
  mono_14: ['14px', '17px', '0px'],
  16: ['16px', '20px', '0px'],
  20: ['20px', '26px', '0px'],
  25: ['25px', '30px', '0px'],
  head_25: ['25px', '25px', '0px'],
  36: ['36px', '42px', '0px'],
  head_36: ['36px', '36px', '0px'],
  48: ['48px', '48px', '0px'],
  64: ['64px', '56px', '0px'],
  108: ['108px', '90px', '0px'],
}

const details = {
  normal: {
    textTransform: 'none',
  },
  lower: {
    textTransform: 'lowercase',
  },
}

const r16 = '16'
const r20 = ['16', '20']
const r25 = ['20', '25']
const r36 = ['25', '36']

const variants = {
  regular_12: ['regular', '12', 'normal'],
  regular_16: ['regular', r16, 'normal'],
  bold_16: ['bold', r16, 'normal'],
  regular_20: ['regular', r20, 'normal'],
  bold_20: ['bold', r20, 'normal'],
  regular_25: ['regular', r25, 'normal'],
  bold_25: ['bold', r25, 'normal'],
  regular_36: ['regular', r36, 'normal'],
  bold_36: ['bold', r36, 'normal'],
  heading_25: ['bold_display', 'head_25', 'lower'],
  heading_36: ['bold_display', ['head_25', 'head_36'], 'lower'],
  heading_48: ['bold_display', ['head_36', '48'], 'lower'],
  heading_64: ['bold_display', ['head_36', null, '48', '64'], 'lower'],
  heading_108: ['bold_display', ['48', null, '64', '108'], 'lower'],
  mono: ['mono', ['mono_12', 'mono_14'], 'lower'],
}

export const typeData = buildTypeObject(styles, sizes)
export const typeVariants = buildTypeVariants(variants, styles, sizes, details)

/*

(8) ["480px", "640px", "820px", "1024px", "1140px", "1440px", "1680px", "1920px"]*/
