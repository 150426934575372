/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'

import { LinkTypes } from '../../lib/helpers'

const Link = (props) => {
  const { to, children, type, ...otherProps } = props

  return to ? (
    type === LinkTypes.EXTERNAL || type === LinkTypes.EMAIL ? (
      <a
        href={to}
        target={type === LinkTypes.EXTERNAL ? '_blank' : null}
        rel="nofollow"
        {...otherProps}
      >
        {children}
      </a>
    ) : (
      <GatsbyLink to={to} {...otherProps}>
        {children}
      </GatsbyLink>
    )
  ) : (
    <span {...otherProps}>{children}</span>
  )
}

export default Link
